<template>
    <div class="Fashion modal-parent">
        <h3 
            class="gradient-text modal-header"
            :style="{ fontSize: `${ $t(`afterlife.hotspots.scene.beauty-and-fashion.header.fontSize.${ stageStore.platform }`) }px`}"
        >
            {{ $t('afterlife.hotspots.scene.beauty-and-fashion.header.label') }}
        </h3>
        <div class="gallery">
            <div class="card" v-for="item in $tm('afterlife.hotspots.scene.beauty-and-fashion.gallery-pose')" :data-cols="item.cols" :key="item.caption">
                <div class="image">
                    <a v-if="item.url" :href="item.url" target="_blank">
                        <img :src="item.src" :alt="item.caption">
                    </a>
                    <img v-else :src="item.src" :alt="item.caption">
                    <h6 v-if="item.caption">{{ item.caption }}</h6>
                </div>
            </div>
        </div>
        <h5 class="modal-subheader">{{ $t('afterlife.hotspots.scene.beauty-and-fashion.subheader') }}</h5>
        <div class="gallery">
            <div class="card" v-for="item in $tm('afterlife.hotspots.scene.beauty-and-fashion.gallery-splat')" :data-cols="item.cols" :key="item.caption">
                <div class="image">
                    <a :href="item.url" target="_blank" @click="sendEvent('splat')">
                        <img :src="item.src" :alt="item.caption">
                    </a>
                    <h6 v-if="item.caption">{{ item.caption }}</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapStores } from 'pinia';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Fashion',
    computed: {
        ...mapStores(useStageStore),
    },
    mounted () {

        this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.FEEL_FREE);

    },
    methods: {
        sendEvent(value) {

            gtag('event', 'link_click', {
                'link_click_value': value,
            });

        }
    }
};

</script>

<style lang="scss" scoped>

.Fashion {
    text-align: center;
    color: color(White);
}

h3 {
    background: linear-gradient(135deg, color(White) 20%, color(Afterlife));
    margin-bottom: 10px;
}

.gallery {

    width: 100%;
    max-width: 1024px;
    margin: 60px auto 0;
    
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @include tablet {
       
       margin: 40px auto 0;

   }

    @include phone {
       
        margin: 40px auto 0;

        grid-template-columns: repeat(1, 1fr);
        gap: 50px;

    }

}

h5 {
    
    margin-top: 120px;

    @include phone {
        margin-top: 80px;
    }

}

.card[data-cols="2"] {

    grid-column: span 2;

    @include phone {
        grid-column: span 1;
    }

}

.card:nth-child(3n - 1) {

    margin-top: 30px;

    @include phone {
        margin-top: 0;
    }

}

.card .image {

    position: relative;

    padding: 1px;
    
    background: linear-gradient(to bottom, color(Afterlife), color(Black), color(Afterlife));

}

.card .image img {
    display: block;
    width: 100%;
}

.card .image h6 {

    position: absolute;
    bottom: -10px;
    left: 20px;

    font-family: 'Caslon Antique', serif;
    font-size: 20px;
    text-transform: uppercase;
    white-space: pre-wrap;
    text-align: left;
    line-height: 1.15;

    @include tablet {
        bottom: -8px;
        font-size: 16px;
    }

    @include mobile {
        left: 15px;
        font-size: 14px;
    }

    @include phone {
        
        bottom: -10px;
        left: 20px;

        font-size: 20px;
    
    }

}

.card:nth-child(even) .image h6 {
    @include phone {
        
        left: auto;
        right: 20px;

        text-align: right;

    }
}

</style>