// Modules.
import { defineStore } from 'pinia';

export const useFooterStore = defineStore('footer', {
    state: () => ({
        areSkullsVisible: false,
        world: 'living-world',
    }),
    getters: {
        visibility: state => ({ skulls: state.areSkullsVisible }),
    },
    actions: {
        setSkullVisibility(isVisible) {

            this.areSkullsVisible = isVisible;
        
        },
        setWorld(world) {

            this.world = world;
        
        },
    }
});