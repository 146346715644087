<template>
    <div class="Lightning">
        <canvas width="1920" height="1080" ref="canvas"></canvas>
    </div>
</template>

<script>

// Modules.
import PNGSequence from '@its2easy/animate-images';

export default {
    name: 'Lightning',
    mounted() {
        
        const images = Array.from(new Array(19), (v, k) => {

            const number = String(k).padStart(3, '0');
            return `/images/lightning/${ number }.png`;
        
        });

        const animation = new PNGSequence(this.$refs.canvas, {
            images,
            preload: 'none',
            loop: false,
            fps: 30,
        });

        this.eventBus.on(this.constants.FLASH_LIGHTNING, () => {
            
            this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.LIGHTNING);
            
            animation.reset();
            animation.play();

        });

    }
};

</script>

<style lang="scss" scoped>

.Lightning {

    position: fixed;
    inset: 0;
    z-index: 9999;

    pointer-events: none;

}

canvas {
    width: 100%;
    height: 100%;
}

</style>