<template>
    <div class="WorldSelection">
        <div class="bg-video" ref="bgVideo">
            <video v-if="stageStore.platform === 'desktop'" src="/videos/home/spiral-green-purple-desktop.mp4" loop autoplay muted playsinline></video>
            <video v-else src="/videos/home/spiral-green-purple-mobile.mp4" loop autoplay muted playsinline></video>
        </div>
        <div ref="portals">
            <h1 :style="{ fontSize: `${ $t(`home.selection.fontSize.${ stageStore.platform }`) }px`}">
                {{ $t('home.selection.label') }}
            </h1>
            <div class="portals">
                <Portal 
                    @click="selectWorld('living-world')"
                    :fontSize="$t(`home.button.portal.fontSize.${ stageStore.platform }`)" theme="green"
                >
                    {{ $t('home.button.portal.livingWorld') }}
                </Portal>
                <Portal 
                    @click="selectWorld('afterlife')"
                    :style="{ 'pointer-events': isAfterlifeClicked ? 'none' : 'auto' }" 
                    :fontSize="$t(`home.button.portal.fontSize.${ stageStore.platform }`)" theme="purple"
                >
                    {{ $t('home.button.portal.afterlife') }}
                </Portal>
            </div>
        </div>
    </div>
</template>

<script>

// Modules.
import { mapStores } from 'pinia';
import gsap from 'gsap';

// Store.
import { useStageStore } from '@/store/stage';
import { useHeaderStore } from '@/store/header';

// Components.
import Portal from '@/components/Button/Portal';

export default {
    name: 'WorldSelection',
    emits: ['purpleClick'],
    components: {
        Portal,
    },
    computed: {
        ...mapStores(useStageStore, useHeaderStore),
    },
    data () {

        return {

            isAfterlifeClicked: false,

        };

    },
    mounted() {

        this.eventBus.emit(this.constants.LOAD_MANIFEST, [
            ...this.assetManifest.BATCH_2.shared,
            ...this.assetManifest.BATCH_2[this.stageStore.platform],
        ]);

    },
    methods: {
        selectWorld(world) {

            if (world === 'living-world') {

                gtag('event', 'button_click', {
                    'button_click_value': 'enter_living_world',
                });

                this.eventBus.emit(this.constants.FLASH_LIGHTNING);
                this.$router.push('/living-world'); 

            } else {

                gtag('event', 'button_click', {
                    'button_click_value': 'enter_afterlife',
                });

                this.isAfterlifeClicked = true;
                this.$emit('purpleClick');
            
            }

            this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.AMBIENT);

        },
        animateIn() {

            gsap.fromTo(this.$refs.bgVideo, { opacity: 0 }, { opacity: 1, duration: 1, delay: 0, ease: 'sine.out' });
            gsap.fromTo(this.$refs.portals, { opacity: 0 }, { opacity: 1, duration: 1, delay: 1.25, ease: 'sine.out' });

        },
        animateOut() {

            gsap.to(this.$refs.portals, { opacity: 0, duration: 1, delay: 0, ease: 'sine.out' });

        },
    }
};

</script>

<style lang="scss" scoped>

.WorldSelection {

    position: fixed;
    inset: 0;

    padding: 150px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mobile {
        padding: 100px 0 125px;
    }

}

.bg-video {
    position: absolute;
    inset: 0;
    z-index: -1;
}

.bg-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

h1 {

    white-space: pre-wrap;
    font-family: 'Caslon Antique', serif;
    text-shadow: 0 6px 12px color(Black);

    text-transform: uppercase;

    margin: 20px 0;

    @include mobile {
        margin: 0 0 35px;
    }

    // For when height <= 750px
    @include micro-height {
        margin: 12px 0;
        font-size: 26px !important;
    }

}

.portals {
   
   display: flex;
   gap: 12px;

   width: 550px;

    // For when height <= 750px
    @include micro-height {
        width: 410px;
    }

    @include mobile {
        width: 340px;
    }

}

.portals button {
   width: 50%;
}

</style>