export default Object.freeze({

    BATCH_1: {
        shared: [
            '/images/lockup-top-green.png',
            '/images/lockup-top-purple.png',
            '/images/lockup-bottom-green.png',
            '/images/lockup-bottom-purple.png',
            '/images/home/portal-btn-green.png',
            '/images/home/portal-btn-purple.png',
        ],
        desktop: [
            '/videos/home/spiral-green-purple-desktop.mp4',
        ],
        mobile: [
            '/videos/home/spiral-green-purple-mobile.mp4',
        ],
    },

    BATCH_2: {
        shared: [
            '/sounds/ambient.mp3',
            '/sounds/laughing.mp3',
            '/sounds/lightning.mp3',
            '/images/home/result-bob.png',
            '/images/home/result-beetlejuice.png',
            '/images/living-world/intro-characters.png',
            '/images/afterlife/intro-characters.png',
            '/images/living-world/hotspot-cast.png',
            '/images/living-world/hotspot-spooky.png',
            '/images/living-world/hotspot-trailer.png',
            '/images/afterlife/hotspot-decor.png',
            '/images/afterlife/hotspot-fashion.png',
            '/images/afterlife/hotspot-generator.png',
            '/images/afterlife/hotspot-wallpaper.png',
            '/images/lightning/000.png',
            '/images/lightning/001.png',
            '/images/lightning/002.png',
            '/images/lightning/003.png',
            '/images/lightning/004.png',
            '/images/lightning/005.png',
            '/images/lightning/006.png',
            '/images/lightning/007.png',
            '/images/lightning/008.png',
            '/images/lightning/009.png',
            '/images/lightning/010.png',
            '/images/lightning/011.png',
            '/images/lightning/012.png',
            '/images/lightning/013.png',
            '/images/lightning/014.png',
            '/images/lightning/015.png',
            '/images/lightning/016.png',
            '/images/lightning/017.png',
            '/images/lightning/018.png',
        ],
        desktop: [
            '/videos/home/spiral-purple-desktop.mp4',
            '/videos/living-world/intro-desktop.mp4',
            '/videos/afterlife/intro-desktop.mp4',
            '/images/living-world/bg-desktop.jpg',
            '/images/afterlife/bg-desktop.jpg',
        ],
        mobile: [
            '/videos/home/spiral-purple-mobile.mp4',
            '/videos/living-world/intro-mobile.mp4',
            '/videos/afterlife/intro-mobile.mp4',
            '/images/living-world/bg-mobile.jpg',
            '/images/afterlife/bg-mobile.jpg',
        ],
    },

    BATCH_WORLD: {
        'living-world': [
            '/images/living-world/cast/astrid.jpg',
            '/images/living-world/cast/beetlejuice.jpg',
            '/images/living-world/cast/delia.jpg',
            '/images/living-world/cast/delores.jpg',
            '/images/living-world/cast/lydia.jpg',
            '/images/living-world/cast/rory.jpg',
            '/images/living-world/cast/shrinker.jpg',
            '/images/living-world/cast/wolf.jpg',
        ],
        'afterlife': [
            '/images/afterlife/decor/001.jpg',
            '/images/afterlife/decor/002.jpg',
            '/images/afterlife/decor/003.jpg',
            '/images/afterlife/decor/004.jpg',
            '/images/afterlife/decor/005.jpg',
            '/images/afterlife/decor/006.jpg',
            '/images/afterlife/decor/007.jpg',
            '/images/afterlife/decor/008.jpg',
            '/images/afterlife/decor/009.jpg',
            '/images/afterlife/decor/010.jpg',
            '/images/afterlife/decor/011.jpg',
            '/images/afterlife/decor/012.jpg',
            '/images/afterlife/fashion/001.jpg',
            '/images/afterlife/fashion/002.jpg',
            '/images/afterlife/fashion/003.jpg',
            '/images/afterlife/fashion/004.jpg',
            '/images/afterlife/fashion/005.jpg',
            '/images/afterlife/fashion/006.jpg',
            '/images/afterlife/fashion/blue-wash.jpg',
            '/images/afterlife/fashion/purple-wash.jpg',
            '/images/afterlife/fashion/silver-wash.jpg',
            '/images/afterlife/fashion/splat-logo.jpg',
            '/images/afterlife/wallpaper/beetlejuice-character-art-preview.jpg',
            '/images/afterlife/wallpaper/beetlejuice-announce-preview.jpg',
            '/images/afterlife/wallpaper/beetlejuice-main-preview.jpg',
            '/images/afterlife/wallpaper/beetlejuice-standee-back-panel-preview.jpg',
            '/images/afterlife/wallpaper/beetlejuice-tsr-preview.jpg',
            '/images/afterlife/wallpaper/empire-swirl-preview.jpg',
        ],
    }

});