export default Object.freeze({
    
    BACK: 'back',

    IN_MY_SPOOKY_ERA: 'in-my-spooky-era',
    MEET_THE_CAST: 'meet-the-cast',
    WATCH_TRAILER: 'watch-trailer',
    DIG_UP_DECOR: 'dig-up-decor',
    AFTERLIFE_GENERATOR: 'afterlife-generator',
    BEAUTY_AND_FASHION: 'beauty-and-fashion',
    DOWNLOAD_WALLPAPER: 'download-wallpaper',

    STEP: {
        INTRO: 'step-intro',
        WORLD_SELECTION: 'step-world-selection',
        LISTENING: 'step-listening',
    },

    SOUNDS: {
        AMBIENT: 'audio-ambient',
        BEETLEJUICE: 'audio-beetlejuice',
        BEETLEJUICE_2: 'audio-beetlejuice-2',
        LIGHTNING: 'audio-lightning',
        LIGHTNING_2: 'audio-lightning-2',
        SCREAM: 'audio-scream',
        SCREAM_2: 'audio-scream-2',
        LAUGHING: 'audio-laughing',
        FEEL_FREE: 'audio-feel-free',
        JUICE_LOOSE: 'audio-juice-loose',
        CUTOFF_SCREAM: 'audio-cutoff-scream'
    },

    PLAY_AUDIO: 'play-audio',
    AUDIO_ENDED: 'audio-ended',

    FLASH_LIGHTNING: 'flash-lightning',

    LOAD_MANIFEST: 'load-manifest',

});
