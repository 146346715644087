<template>
    <div class="Hotspots">
        <div class="canvas">
            <Intro v-if="!isIntroComplete" :world="world" ref="intro" />
            <Scene :world="world" :show-badges="showBadges" @openModal="openModal" />
            <Transition name="fade-2">
                <Modal v-if="modal" :world="world" :id="modal" />
            </Transition>
            <div class="vignette"></div>
            <Transition name="fade-1">
                <div v-if="showBadges" class="world-toggle">
                    <h6>{{ $t('shared-world.toggle-header') }}</h6>
                    <Toggle :world="world" />
                </div>
            </Transition>
            <div class="swipe-cta" v-if="showBadges">
                <img src="/images/skeleton-hand.png" alt="">
                <h3>{{ $t('shared-world.explore') }}</h3>
                <img src="/images/skeleton-hand.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>

// Modules.
import { mapStores } from 'pinia';

// Components.
import Intro from '@/components/World/Intro';
import Scene from '@/components/World/Scene';
import Modal from '@/components/World/Modal';
import Toggle from '@/components/World/Toggle';

// Store.
import { useHeaderStore } from '@/store/header';

export default {
    name: 'Hotspots',
    components: {
        Intro,
        Scene,
        Modal,
        Toggle,
    },
    props: {
        world: { type: String, required: true },
    },
    computed: {
        ...mapStores(useHeaderStore),
    },
    data() {

        return {
            isIntroComplete: false,
            showBadges: false,
            modal: null,
            isTimeoutCalled: false,
            timeout: null
        };
    
    },
    mounted() {

        this.timeout = setTimeout(() => {

            this.$refs.intro.animateOut(() => {

                this.isIntroComplete = true;
                this.showBadges = true;
            
            });

        }, 3000);

        this.eventBus.on(this.constants.BACK, this.closeModal);
    
    },
    unmounted() {

        clearTimeout(this.timeout);

    },
    methods: {
        openModal(id) {

            this.modal = id;
            this.showBadges = false;

            this.headerStore.setBackVisibility(true);
        
        },
        closeModal() {
            
            this.modal = null;
            this.showBadges = true;

            this.headerStore.setBackVisibility(false);

        },
    }
};

</script>

<style lang="scss" scoped>

.Hotspots {

    width: 100%;
    height: 100%;

    background: color(Black);

}

.vignette,
.canvas {
    position: fixed;
    inset: 0;
}

.vignette {

    z-index: 10;

    background: linear-gradient(to bottom, color(Black, 0.8), transparent 25%, transparent 75%, color(Black, 0.8));

    pointer-events: none;

}

.world-toggle {

    position: fixed;
    top: 200px;
    left: 50%;
    z-index: 20;

    transform: translateX(-50%);

    text-align: center;

    @include mobile {
        top: 140px;
    }

    @include phone {
        top: 110px;
    }

}

.world-toggle h6 {

    font-family: 'Caslon Antique', serif;
    font-size: 20px;
    color: color(White);
    text-transform: uppercase;

    margin-bottom: 20px;

    @include phone {
        font-size: 16px;
        margin-bottom: 12px;
    }

}

.swipe-cta {

    display: none;

    position: fixed;
    bottom: 140px;
    z-index: 10;

    width: 100%;

    justify-content: center;
    align-items: center;
    gap: 10px;

    text-align: center;

    @include mobile {
        display: flex;
    }

    @include phone {
        bottom: 125px;
    }

}

.swipe-cta h3 {

    font-family: 'Caslon Antique', serif;
    font-size: 16px;
    color: color(white);
    text-transform: uppercase;

}

.swipe-cta img {
    height: 24px;
}

.swipe-cta img:last-of-type {
    transform: scaleX(-1);
}

// Vue Transition 1:

.fade-1-enter-active {
  transition: opacity 0.5s ease;
}

.fade-1-leave-active {
    transition: opacity 0.5s ease;
}

.fade-1-enter-from {
  opacity: 0;
}

// Vue Transition 2:

.fade-2-enter-active {
  transition: opacity 0.5s ease 0.5s;
}

.fade-2-leave-active {
    transition: opacity 0.5s ease;
}

.fade-2-enter-from {
  opacity: 0;
}

</style>