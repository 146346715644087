<template>
    <div class="BottomBar" ref="footer">
        <div class="legal">
            <div class="group">
                <a :href="$t('footer.privacy-policy.url')" target="_blank" @click="sendEvent('privacy_policy')">
                    <p>{{ $t('footer.privacy-policy.label') }}</p>
                </a>
                <a :href="$t('footer.terms.url')" target="_blank" @click="sendEvent('terms_of_use')">
                    <p>{{ $t('footer.terms.label') }}</p>
                </a>
                <a :href="$t('footer.cookies.url')" target="_blank" @click="sendEvent('cookie_settings')">
                    <p>{{ $t('footer.cookies.label') }}</p>
                </a>
                <p class="credits" @click="toggleBilling(true)">{{ $t('footer.credits.label') }}</p>
            </div>
            <p>{{ $t('footer.copyright') }}</p>
        </div>
        <a class="tickets" :href="$t('footer.tickets.url')" target="_blank" @click="sendEvent('get_tickets')">
            <img src="/images/skull-btn.png" alt="">
            <span :style="{ fontSize: `${ $t(`footer.tickets.fontSize.${ stageStore.platform }`) }px`, bottom: `${ $t(`footer.tickets.offset.${ stageStore.platform }`) }%` }">{{ $t('footer.tickets.label') }}</span>
        </a>
        <div v-if="footerStore.visibility.skulls" class="skulls">
            <img :src="`/images/${ footerStore.world }/skull.png`" alt="">
            <img :src="`/images/${ footerStore.world }/skull.png`" alt="">
        </div>
        <div :class="{ 'billing-block': true, open: isBillingBlockOpen }">
            <img class="credits" :src="$t('footer.billing-block')" alt="">
            <img class="close" src="/images/back.png" alt="" @click="toggleBilling(false)">
        </div>
    </div>
</template>

<script>

// Modules.
import { mapStores } from 'pinia';
import gsap from 'gsap';

// Store.
import { useStageStore } from '@/store/stage';
import { useFooterStore } from '@/store/footer';

export default {
    name: 'BottomBar',
    computed: {
        ...mapStores(useStageStore, useFooterStore),
    },
    data() {

        return {
            isBillingBlockOpen: false,
        };
    
    },
    mounted() {

        gsap.to(this, { isBillingBlockOpen: true, delay: 0, onComplete: () => {

            gsap.to(this, { isBillingBlockOpen: false, delay: 2 });
        
        } });

    },
    methods: {
        toggleBilling(isOpen) {

            this.isBillingBlockOpen = isOpen;
        
        },
        sendEvent(value) {

            gtag('event', 'link_click', {
                'link_click_value': value,
            });

        },
    }
};

</script>

<style lang="scss" scoped>

.BottomBar {

    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    width: 100%;
    height: 185px;

    font-family: 'Caslon Antique', serif;
    font-size: 12px;
    text-transform: uppercase;

    pointer-events: none;

    background: linear-gradient(to top, color(Black) 25%, transparent);

    @include mobile {
        height: 135px;
    }

    @include phone {
        font-size: 8.9px;
    }

}

.legal {

    position: relative;
    z-index: 15;

    width: 100%;
    padding: 0 10vw 10px;

    text-align: center;

    color: color(White);

    @media (max-width: 1920px) {
        padding: 0 5vw 10px;
    }

    @include mobile {
        padding: 0 15px 10px;
    }

}

.legal .group {
    
    display: inline-flex;
    gap: 16px;

    margin-bottom: 8px;

}

.legal .credits {
    pointer-events: auto;
    cursor: pointer;
}

.legal a {

    color: inherit;
    text-decoration: none;

    pointer-events: auto;

}

a.tickets {

    position: absolute;
    bottom: 60px;
    left: 50%;

    transform: translateX(-50%);

    font-family: 'Ramaraja', serif;
    color: color(White);
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;

    padding: 0;

    pointer-events: auto;

    @include phone {
        bottom: 50px;
    }

}

a.tickets img {
    
    width: 260px;
    aspect-ratio: 536/170; // Intrinsic size.

    @include mobile {
        width: 200px;
    }

}

a.tickets span {

    position: absolute;
    left: 0;
    white-space: pre-wrap;
    line-height: 1.1;

    width: 100%;

}

.skulls {
    
    position: absolute;
    bottom: 0;
    z-index: 5;

    width: 100%;

    display: flex;
    justify-content: center;
    gap: 340px;

    @include mobile {
        padding-bottom: 60px;
        gap: 240px;
    }

}

.skulls img {
    
    height: 75px;
   
    @include tablet {
        height: 50px;
    }

}

.skulls img:last-child {
    transform: scaleX(-1);
}

.billing-block {

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;

    width: 100%;
    padding: 36px 10vw 70px;

    background: color(Black);

    transform: translateY(0);
    transition: transform 500ms ease;

    @media (max-width: 1920px) {
        padding: 36px 5vw 70px;
    }

    @include desktop {
        padding: 74px 5vw 70px;
    }

    @include mobile {
        padding: 74px 15px 70px;
    }

    @include phone {
        padding: 54px 15px 60px;
    }

}

.billing-block:not(.open) {
    transform: translateY(100%);
}

.billing-block img.credits {
    
    display: block;

    width: 100%;
    max-width: 1024px;
    margin: 0 auto;

    @include desktop {
        margin: 16px auto 0;
    }

}

.billing-block img.close {

    position: absolute;
    top: 36px;
    right: 10vw;

    height: 38px;

    transform: rotate(-90deg);

    pointer-events: auto;
    cursor: pointer;

    @media (max-width: 1920px) {
        right: 5vw;
    }

    @include mobile {
        right: 15px;
    }

    @include phone {
        top: 15px;
        height: 34px;
    }

}

</style>