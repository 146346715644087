<template>
    <div class="Toggle">
        <div @click="selectWorld('living-world')" :class="{ world: true, 'living-world': true, active: world === 'living-world' }">
            <img :src="`/images/toggle-living-world-${ world === 'living-world' ? 'active' : 'inactive' }.png`" alt="">
            <span :style="{ fontSize: `${ $t(`shared-world.living-world-header.fontSize.${ stageStore.platform }`) }px` }">{{ $t('shared-world.living-world-header.label') }}</span>
        </div>
        <div @click="selectWorld('afterlife')" :class="{ world: true, 'afterlife': true, active: world === 'afterlife' }">
            <img :src="`/images/toggle-afterlife-${ world === 'afterlife' ? 'active' : 'inactive' }.png`" alt="">
            <span :style="{ fontSize: `${ $t(`shared-world.afterlife-header.fontSize.${ stageStore.platform }`) }px` }">{{ $t('shared-world.afterlife-header.label') }}</span>
        </div>
    </div>
</template>

<script>

// Modules.
import { mapStores } from 'pinia';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Toggle',
    props: {
        world: { type: String, required: true },
    },
    computed: {
        ...mapStores(useStageStore),
    },
    methods: {
        selectWorld(world) {
            
            if (world === this.world) {

                return; 

            }

            if (world === 'living-world') {

                gtag('event', 'button_click', {
                    'button_click_value': 'toggle_living_world',
                });
            
            } else {

                gtag('event', 'button_click', {
                    'button_click_value': 'toggle_afterlife',
                });
            
            }

            this.eventBus.emit(this.constants.FLASH_LIGHTNING);
            this.$router.push(`/${ world }`);
            
        }
    }
};

</script>

<style lang="scss" scoped>

.Toggle {
    display: flex;
    align-items: center;
    gap: 5px;
}

.world {
    
    position: relative;
    cursor: pointer;

    transition: transform 300ms ease;

}

.world:active:not(.active) {
    transform: scale(0.95);
}

.world img {
    
    display: block;

    width: 250px;
    aspect-ratio: 540/126; // Intrinsic size.

    @include phone {
        width: 46vw;
    }

}

.world span {
    
    position: absolute;
    inset: 0;

    padding-top: 9px;

    text-align: center;
    text-transform: uppercase;
    white-space: pre-wrap;

    display: flex;
    align-items: center;
    justify-content: center;

    @include phone {
        padding-top: 7px;
    }

}

.world.living-world span {
    color: color(LivingWorld);
}

.world.afterlife span {
    color: color(Afterlife);
}

.world.active span {
    color: color(Black);
}

.world.active.living-world {
    box-shadow: 0 0 40px color(LivingWorld, 0.8);
}

.world.active.afterlife {
    box-shadow: 0 0 40px color(Afterlife, 1);
}

</style>