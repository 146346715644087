<template>
    <button :class="{ Portal: true, [theme]: true }" :style="{ fontSize: `${ fontSize }px` }">
        <img class="skull-icon-top" :src="`/images/lockup-top-${ theme }.png`" alt="upright winged skull icon">
        <slot />
        <img class="skull-icon-bottom" :src="`/images/lockup-bottom-${ theme }.png`" alt="downwards winged skull icon">
    </button>
</template>

<script>

export default {
    name: 'Portal',
    props: {
        theme: { type: String, validator(value) {

            return ['green', 'purple'].includes(value); 

        }, required: true },
        fontSize: { type: String, required: true },
    },
};

</script>

<style lang="scss" scoped>

.Portal {

    // Reset.
    appearance: none;
    background: none;
    border: none;
    outline: none;
    // **

    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    aspect-ratio: 327/392; // Intrinsic image size.
    padding: 0 10px;

    background-size: 100%;

    font-family: 'Ramaraja', serif;
    line-height: 1;
    color: color(White);
    text-transform: uppercase;
    white-space: pre-wrap;

    transition: transform 300ms ease;

    // For when height <= 750px
    @include micro-height {
        font-size: 18px !important;
    }

    @include mobile {
        background-repeat: no-repeat;
    }

}

.skull-icon-top {

    width: 164px;
    margin-bottom: 20px;

    // For when height <= 750px
    @include micro-height {
        width: 110px;
    }

    @include mobile {
        width: 82px;
        margin-bottom: 14px;
    }
}

.skull-icon-bottom {

    width: 192px;
    margin-top: 4px;

    // For when height <= 750px
    @include micro-height {
        width: 130px;
    }

    @include mobile {
        width: 96px;
        margin-top: 8px;
    }
}

.skull-icon-top,
.skull-icon-bottom {
    transition: transform 300ms ease;
}

.Portal:hover .skull-icon-top {
    transform: translateY(-10%);
}

.Portal:hover .skull-icon-bottom {
    transform: translateY(10%);
}

.Portal:active {
    transform: scale(0.95);
}

.Portal.green {
    background-image: url('/images/home/portal-btn-green.png');
}
.Portal.purple {
    background-image: url('/images/home/portal-btn-purple.png');
}

</style>