// Modules.
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { createWebHistory, createRouter } from 'vue-router';
import { createPinia } from 'pinia';
import mitt from 'mitt';

// Styles.
import '@/styles/index.scss';

// Engine.
import App from '@/App.vue';
import routes from '@/routes';

// Misc.
import constants from '@/constants';
import assetManifest from '@/asset-manifest';

function loadMessages() {

    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};

    locales.keys().forEach(key => {

        const matched = key.match(/([A-Za-z0-9-_]+)\./i);

        if (matched && matched.length > 1) {

            const locale = matched[1];
            messages[locale] = locales(key);
        
        }

    });

    return messages;

}

const i18n = createI18n({
    locale: process.env.VUE_APP_LOCALE,
    fallbackLocale: 'en',
    messages: loadMessages(),
});

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const eventBus = mitt();
const pinia = createPinia();
const app = createApp(App);

app.config.globalProperties.eventBus = eventBus;
app.config.globalProperties.constants = constants;
app.config.globalProperties.assetManifest = assetManifest;

app.use(pinia);
app.use(router);
app.use(i18n);
app.mount('#app');