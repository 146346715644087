<template>
    <div class="Intro">
        <Lockup 
            :title="$t('home.intro.lockup.title')" 
            :src="{top: '/images/lockup-top.png', bottom: '/images/lockup-bottom.png'}"
            :font-size="{ desktop: $t(`home.intro.lockup.fontSize.desktop`), mobile: $t(`home.intro.lockup.fontSize.mobile`)}"
            theme="white"
            font="caslon"
            ref="lockup"
        />
        <div ref="audio">
            <img class="volume" src="/images/volume.png" alt="">
            <p>{{ $t(`home.intro.audio.label`) }}</p>
        </div>
    </div>
</template>

<script>

// Modules.
import gsap from 'gsap';
import { mapStores } from 'pinia';

// Components.
import Lockup from '@/components/Lockup';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Intro',
    components: {
        Lockup,
    },
    computed: {
        ...mapStores(useStageStore),
    },
    mounted() {

        this.eventBus.emit(this.constants.LOAD_MANIFEST, [
            ...this.assetManifest.BATCH_1.shared,
            ...this.assetManifest.BATCH_1[this.stageStore.platform],
        ]);
    
    },
    methods: {
        animateIn() {
            // Not needed. We'll start "in" due to the footer requirement.
        },
        animateOut() {

            gsap.to(this.$refs.lockup.$el, { opacity: 0, duration: 1, delay: 0, ease: 'sine.out' });
            gsap.to(this.$refs.audio, { opacity: 0, duration: 1, delay: 0.25, ease: 'sine.out' });

        },
    }
};

</script>

<style lang="scss" scoped>

.Intro {

    position: fixed;
    inset: 0;

    background-image: url(/images/home/landing-bg.jpg);
    background-size: cover;
    background-position: center;

    padding: 200px 0 150px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

img.volume {

    height: 40px;
    margin: 8vh 0 20px;

    @media (min-width: 1024px) and (max-height: 700px) {
        height: 32px;
        margin: 4vh 0 20px;
    }

    @include mobile {
        height: 32px;
    }

}

p {

    font-size: 24px;
    
    @media (min-width: 1024px) and (max-height: 700px) {
        font-size: 18px;
    }

    @include mobile {
        font-size: 18px;
        white-space: pre-wrap;
    }

}

</style>