<template>
    <div class="Modal">
        <Spooky v-if="id === constants.IN_MY_SPOOKY_ERA" />
        <Cast v-if="id === constants.MEET_THE_CAST" />
        <Trailer v-if="id === constants.WATCH_TRAILER" />
        <Decor v-if="id === constants.DIG_UP_DECOR" />
        <Generator v-if="id === constants.AFTERLIFE_GENERATOR" />
        <Fashion v-if="id === constants.BEAUTY_AND_FASHION" />
        <Wallpaper v-if="id === constants.DOWNLOAD_WALLPAPER" />
    </div>
</template>

<script>

// Components.
import Spooky from '@/components/World/Modal/Spooky';
import Cast from '@/components/World/Modal/Cast';
import Trailer from '@/components/World/Modal/Trailer';
import Decor from '@/components/World/Modal/Decor';
import Generator from '@/components/World/Modal/Generator';
import Fashion from '@/components/World/Modal/Fashion';
import Wallpaper from '@/components/World/Modal/Wallpaper';

export default {
    name: 'Modal',
    components: {
        Spooky,
        Cast,
        Trailer,
        Decor,
        Generator,
        Fashion,
        Wallpaper,
    },
    props: {
        world: { type: String, required: true },
        id: { type: String, required: true },
    }
};

</script>

<style lang="scss" scoped>

.Modal {

    position: fixed;
    inset: 0;
    z-index: 20;

    background: color(Black, 0.6);

    overflow: hidden;
    overflow-y: auto;

}

</style>