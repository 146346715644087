<template>
    <div class="Decor modal-parent">
        <h3 
            class="gradient-text modal-header"
            :style="{ fontSize: `${ $t(`afterlife.hotspots.scene.dig-up-decor.header.fontSize.${ stageStore.platform }`) }px`}"
        >
            {{ $t('afterlife.hotspots.scene.dig-up-decor.header.label') }}
        </h3>
        <div class="gallery">
            <div class="card" v-for="(item, index) in $tm('afterlife.hotspots.scene.dig-up-decor.gallery')" :data-cols="item.cols" :key="index">
                <div class="image">
                    <img :src="item.src" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapStores } from 'pinia';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Decor',
    computed: {
        ...mapStores(useStageStore),
    },
    mounted () {

        this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.LIGHTNING_2);

    }
};

</script>

<style lang="scss" scoped>

.Decor {
    text-align: center;
    color: color(White);
}

h3 {
    background: linear-gradient(135deg, color(White) 20%, color(Afterlife));
    margin-bottom: 10px;
}

.gallery {

    width: 100%;
    max-width: 1200px;
    margin: 60px auto 0;
    
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;

    @include tablet {
        grid-template-columns: repeat(2, 1fr);
    }

    @include phone {
       
        margin: 40px auto 0;

        grid-template-columns: repeat(1, 1fr);
        gap: 60px;

    }

}

.card[data-cols="2"] {

    grid-column: span 2;

    @include phone {
        grid-column: span 1;
    }

}

.card:nth-child(even) {

    margin-top: 30px;

    @include phone {
        margin-top: 0;
    }

}

.card .image {

    position: relative;

    padding: 1px;
    
    background: linear-gradient(to bottom, color(Afterlife), color(Black), color(Afterlife));

}

.card .image img {
    display: block;
    width: 100%;
}

</style>