// Modules.
import { defineStore } from 'pinia';

export const useStageStore = defineStore('stage', {
    state: () => ({ width: 0, height: 0 }),
    getters: {
        dimensions: state => ({ width: state.width, height: state.height }),
        platform: state => (state.width > 768 ? 'desktop' : 'mobile'),
    },
    actions: {
        setDimensions(width, height) {

            this.width = width;
            this.height = height;
        
        }
    }
});