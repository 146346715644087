<template>
    <div id="World">
        <Transition name="fade-2" mode="out-in">
            <Hotspots v-if="isTeaserComplete" :world="world" key="hotspots" />
            <Teaser v-else :world="world" @onEnd="hideTeaser" key="teaser" />
        </Transition>
    </div>
</template>

<script>

// Modules.
import { mapStores } from 'pinia';

// Components.
import Teaser from '@/components/World/Teaser';
import Hotspots from '@/components/World/Hotspots';

// Store.
import { useFooterStore } from '@/store/footer';

export default {
    name: 'World',
    components: {
        Teaser,
        Hotspots,
    },
    computed: {
        ...mapStores(useFooterStore),
    },
    data() {

        return {
            world: this.$route.params.world,
            isTeaserComplete: false,
        };
    
    },
    mounted() {

        this.footerStore.setWorld(this.world);
        this.footerStore.setSkullVisibility(false);

        this.eventBus.emit(this.constants.LOAD_MANIFEST, [
            ...this.assetManifest.BATCH_WORLD[this.world],
        ]);
    
    },
    methods: {
        hideTeaser() {

            this.isTeaserComplete = true;
            this.footerStore.setSkullVisibility(true);
        
        }
    }
};

</script>

<style lang="scss" scoped>

#World {

    position: fixed;
    inset: 0;

    background: color(Black);

}

</style>

<style lang="scss">

.fade-2-enter-active,
.fade-2-leave-active {
    transition: opacity 1000ms ease;
}

.fade-2-enter-from,
.fade-2-leave-to {
    opacity: 0;
}

</style>