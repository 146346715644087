<template>
    <div id="Home">
        <Transition name="fade-1" mode="in-out">
            <Intro v-if="step === constants.STEP.INTRO" :key="constants.STEP.INTRO" ref="intro" />
            <WorldSelection v-else-if="step === constants.STEP.WORLD_SELECTION" @purpleClick="purpleClick" :key="constants.STEP.WORLD_SELECTION" ref="worldSelection" />
            <Listening 
                v-else-if="step === constants.STEP.LISTENING" 
                :resultImage="resultImage" 
                :passwordResult="passwordResult" 
                :isMicrophoneNotListening="isMicrophoneNotListening"
                @skipPurple="skipPurple"
                @onMute="onMute"
                :key="constants.STEP.LISTENING"
                ref="listening"
            />
        </Transition>
    </div>
</template>

<script>

// Modules.
import Bowser from 'bowser';
import convertToWav from 'wav-blob-util';
import { mapStores } from 'pinia';

// Components.
import Intro from '@/components/Home/Intro';
import WorldSelection from '@/components/Home/WorldSelection';
import Listening from '@/components/Home/Listening';

// Store.
import { useFooterStore } from '@/store/footer';

export default {
    name: 'Home',
    components: {
        Intro,
        WorldSelection,
        Listening
    },
    computed: {
        ...mapStores(useFooterStore),
    },
    data () {

        return {

            // Template
            step: this.constants.STEP.INTRO,

            // Props
            resultImage: '',
            passwordResult: '',

            // API
            speechRecognition: 'web',
            text: '',
            isErrorWatchOn: true,
            isRecognitionStarted: false,
            isMicrophoneNotListening: false,
            isMicrophoneActive: false,

            // Web Speech
            recognition: null,

            // Google
            audioStream: null,
            recording: null

        };
    
    },
    created() {

        // Object containing users browser info:
        const browser = Bowser.getParser(window.navigator.userAgent);

        if ((browser.parsedResult.browser.name === 'Firefox') ||
            (browser.parsedResult.browser.name === 'Opera')) {

            this.speechRecognition = 'google';

        } else {

            // Web Speech API

            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

            if (SpeechRecognition) {

                this.recognition = new SpeechRecognition();
                this.recognition.lang = 'en-US';
                this.recognition.continuous = true;
                this.recognition.interimResults = true;

                this.recognition.onstart = () => {

                    this.isRecognitionStarted = true;

                };

                this.recognition.onresult = (event) => {

                    const transcript = Array.from(event.results)
                        .map((result) => result[0])
                        .map((result) => result.transcript)
                        .join('');
                    this.text = transcript;

                };

                this.recognition.onerror = (event) => {

                    if (event.error === 'no-speech') {

                        return;

                    }

                    this.isMicrophoneNotListening = true;

                };

                this.recognition.onend = () => {

                    if (this.isMicrophoneActive) {

                        this.startListening();

                    }

                };

            } else {

                console.error('SpeechRecognition is not supported in this browser.');

            }

        }

    },
    mounted() {

        this.footerStore.setSkullVisibility(false);
        this.$refs.intro.animateIn();

        setTimeout(() => this.advance(this.constants.STEP.WORLD_SELECTION), 4000);
    
    },
    methods: {

        advance(step) {

            this.step = step;

            switch (step) {

            default:
                break;
            case this.constants.STEP.WORLD_SELECTION:
                this.$refs.intro.animateOut();
                this.$nextTick(() => {

                    this.$refs.worldSelection.animateIn();
                
                });
                break;
            case this.constants.STEP.LISTENING:
                this.$refs.worldSelection.animateOut();
                this.$nextTick(() => {

                    this.$refs.listening.animateIn();
                
                });
                break;
            
            }

        },

        async record() {

            try {

                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const chunks = [];

                this.audioStream = stream;
                this.recording = new MediaRecorder(stream);

                this.recording.start();

                this.recording.onstart = () => {

                    this.isRecognitionStarted = true;

                };

                this.recording.onerror = () => {

                    this.isMicrophoneNotListening = true;
                    clearInterval(transcribeInterval);

                };

                this.recording.onstop = () => {

                    clearInterval(transcribeInterval);

                };

                this.recording.ondataavailable = async e => {

                    chunks.push(e.data);

                    const blob = new Blob(chunks, { type: this.recording.mimeType });
                    const wav = await convertToWav(blob);

                    this.uploadData(wav);

                };

                const transcribeInterval = setInterval(this.transcribe, 3000);

            } catch (error) {

                // If microphone access blocked, start muted path:
                this.isMicrophoneNotListening = true;

            }

        },
        transcribe() {

            if (this.recording && this.recording.state === 'recording') {

                this.recording.requestData();

            } else {

                console.error('MediaRecorder is turned off');

            }
            
        },
        uploadData(blob) {

            const filename = new Date().toISOString();
            const xhr = new XMLHttpRequest();

            xhr.onload = e => {

                if (e.target.readyState === 4) {

                    this.text = e.target.responseText;
                
                }

            };

            const formData = new FormData();

            formData.append('audioData', blob, filename);

            xhr.open('POST', 'https://api.say-my-name-beetlejuice.com/transcribe', true);
            xhr.send(formData);

        },
        startListening() {

            if (this.speechRecognition === 'web') {

                this.recognition.start();

            } else {

                this.record();

            }

        },
        stopListening() {

            if (this.speechRecognition === 'web') {

                this.recognition.stop();

            } else {

                if (this.recording && this.recording.state === 'recording') {

                    this.recording.stop();
                    this.audioStream.getTracks().forEach(track => track.stop());

                }

            }

        },
        purpleClick () {

            this.startListening();

            const successWatch = this.$watch('isRecognitionStarted', (newValue) => {

                if (newValue) {

                    this.advance(this.constants.STEP.LISTENING);
                    this.isMicrophoneActive = true;
                    this.passwordListening();
                    successWatch();

                }
                
            });
            const errorWatch = this.$watch('isMicrophoneNotListening', (newValue) => {

                if (newValue && this.isErrorWatchOn) {

                    this.advance(this.constants.STEP.LISTENING);
                    errorWatch();

                }

            });

        },
        passwordListening() {

            setTimeout(() => {
                
                if (this.passwordResult === '') {

                    gtag('event', 'speech_result', {
                        'speech_result_value': 'fail', 
                    });

                    this.stopListening();
                    this.isErrorWatchOn = false;
                    this.isMicrophoneActive = false;
                    this.passwordResult = 'fail';
                    this.resultImage = 'bob';
                    this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.LAUGHING);

                }
            
            }, '18000');

            const passwordWatcher = this.$watch('text', () => {

                const beetlejuiceCount = (this.text.match(/beetlejuice|juice|beetle/gi) || []).length;

                if (beetlejuiceCount >= 3 ) {

                    gtag('event', 'speech_result', {
                        'speech_result_value': 'success',
                    });

                    this.isMicrophoneActive = false;
                    this.stopListening();
                    this.passwordResult = 'success';
                    this.resultImage = 'beetlejuice';
                    this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.LAUGHING);
                    passwordWatcher();

                } 
                
            });

        },
        skipPurple () {

            gtag('event', 'speech_result', {
                'speech_result_value': 'skip', 
            });

            this.passwordResult = 'skip';
            this.resultImage = 'beetlejuice';
            this.isErrorWatchOn = false;
            this.isMicrophoneActive = false;
            this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.LAUGHING);
            this.stopListening();

        },
        onMute () {

            gtag('event', 'speech_result', {
                'speech_result_value': 'muted', 
            });

            this.passwordResult = 'muted';
            this.resultImage = 'beetlejuice';

        }
    }

};

</script>

<style lang="scss" scoped>

#Home {

    color: white;
    text-align: center;

    position: fixed;
    inset: 0;

}

</style>

<style lang="scss">

.fade-1-enter-active,
.fade-1-leave-active {
    transition: opacity 2000ms ease;
}

.fade-1-enter-from,
.fade-1-leave-to {
    opacity: 0;
}

</style>