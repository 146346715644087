// Views.
import Home from '@/views/Home.vue';
import World from '@/views/World.vue';

const routes = [
    {
        path: '/',
        component: Home,
    },
    {
        path: '/:world',
        component: World,
    },
];

export default routes;