<template>
    <div class="Listening">
        <div class="bg-video" ref="bgVideo">
            <video v-if="stageStore.platform === 'desktop'" src="/videos/home/spiral-purple-desktop.mp4" loop autoplay muted playsinline></video>
            <video v-else src="/videos/home/spiral-purple-mobile.mp4" loop autoplay muted playsinline></video>
        </div>
        <Transition name="fade-3" mode="out-in" @enter="onEnter">
            <div class="listening-container" v-if="passwordResult === ''" ref="prompt" key="prompt">
                <p class="magic-copy" :style="{ fontSize: `${ $t(`home.listening.magic.fontSize.${ stageStore.platform }`) }px`}">
                    {{ $t('home.listening.magic.label') }}
                </p>
                <div class="quote">
                    <img class="top-quotation" src="/images/home/quote-top.png" alt="">
                    <h1 class="beetlejuice-copy" 
                        :style="{ fontSize: `${ $t(`home.listening.beetlejuice.fontSize.${ stageStore.platform }`) }px`}"
                    >
                        {{ $t('home.listening.beetlejuice.label') }}
                    </h1>
                    <img class="bottom-quotation" src="/images/home/quote-bottom.png" alt="">
                </div>
                <img class="animated-audio" src="/images/home/audio-detector.png" alt="">
                <p @click="this.$emit('skipPurple');" v-if="!isMicrophoneNotListening" class="error-copy" :style="{ fontSize: `${ $t(`home.listening.error.fontSize.${ stageStore.platform }`) }px`}">
                    <u>{{ $t('home.listening.error.label') }}</u>
                </p>
            </div>
            <div class="result-container" ref="result" v-else-if="passwordResult !== ''" key="result">
                <h1 class="result-copy" :style="{ fontSize: `${ $t(`home.results.${ passwordResult }.fontSize.${ stageStore.platform }`) }px`}">
                    {{ $t(`home.results.${ passwordResult }.label`) }}
                </h1>
                <img :class="`${ resultImage }-img`" :src="`/images/home/result-${ this.resultImage }.png`" alt="" ref="character">
            </div>
        </Transition>
    </div>
</template>

<script>

// Modules.
import { mapStores } from 'pinia';
import gsap from 'gsap';

// Store.
import { useStageStore } from '@/store/stage';
import { useHeaderStore } from '@/store/header';

export default {
    name: 'Listening',
    emits: ['skipPurple', 'onMute'],
    props: {
        passwordResult: { type: String, required: true },
        resultImage: { type: String, required: true },
        isMicrophoneNotListening: { type: Boolean, required: true }
    },
    computed: {
        ...mapStores(useStageStore, useHeaderStore),
    },
    mounted() {

        if (this.isMicrophoneNotListening) {

            this.playSoundBite();
        
        }

        this.eventBus.on(this.constants.AUDIO_ENDED, track => {

            if (track === this.constants.SOUNDS.BEETLEJUICE) {

                this.$emit('onMute');
                this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.LAUGHING);
            
            }
        
        });

    },
    methods: {
        animateIn() {

            gsap.fromTo(this.$refs.bgVideo, { opacity: 0 }, { opacity: 1, duration: 1, delay: 0, ease: 'sine.out' });
            gsap.fromTo(this.$refs.prompt, { opacity: 0 }, { opacity: 1, duration: 1, delay: 1, ease: 'sine.out' });

        },
        animateOut() {
            // @TODO: ...
        },
        playSoundBite () {
                
            this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.BEETLEJUICE);

        },
        onEnter() {

            gsap.fromTo(this.$refs.character, { y: '100%' }, { y: '0%', ease: 'back.out(1.7)', duration: 0.75 });

            setTimeout(() => {

                this.eventBus.emit(this.constants.FLASH_LIGHTNING);
                this.$router.push('/afterlife');

            }, 4000);

        },
    },
};

</script>

<style lang="scss" scoped>

.Listening {

    position: fixed;
    inset: 0;

    padding: 150px 0 100px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.bg-video {
    position: absolute;
    inset: 0;
    z-index: -1;
}

.bg-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.magic-copy {
    font-family: 'Caslon Antique', serif;
    white-space: pre-wrap;
    text-transform: uppercase;

    @include micro-height {
        font-size: 25px !important;
    }
}

.quote {
    display: flex;

    margin: 15px 0 10px;

    white-space: pre-wrap;
}

.top-quotation {
    height: 20px;
    margin: -5px 10px;

    @include mobile {
        height: 15px;
    }
}

.bottom-quotation {
    align-self: flex-end;

    height: 20px;
    margin: 15px 10px;

    @include mobile {
        height: 15px;
        margin: 5px 10px;
    }
}

.beetlejuice-copy {
    background: linear-gradient(to right, #ffffff, #7529D5);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;

    // To give some of the accents room:
    padding-top: 14px;

    @include micro-height {
        font-size: 55px !important;
    }
}

.animated-audio {

    width: 220px;

    @include micro-height {
        width: 180px;
    }

    @include mobile {
        width: 135px;
    }
}

.error-copy {
    cursor: pointer;
    margin: 30px 0;

    font-family: 'Caslon Antique', serif;

    @include micro-height {
        margin: 15px 0;
        font-size: 20px !important;
    }

    @include mobile {
        white-space: pre-wrap;
    }
}

// Results

.result-container {
    background: linear-gradient(to right, #ffffff, #7529D5);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;

    white-space: pre-wrap;
}

.result-copy {

    margin-bottom: 30px;
    // To give some of the accents room:
    padding-top: 20px;
    text-transform: uppercase;
    
}

.result-container img {
    position: fixed;
    left: 0;
    bottom: 0;

    @include mobile {
        transform: translateX(-50px);
    }
}

.result-container img {
    width: 400px;

    @include mobile {
        width: 200px;
    }
}

</style>

<style lang="scss">

.fade-3-enter-active,
.fade-3-leave-active {
    transition: opacity 500ms ease;
}

.fade-3-enter-from,
.fade-3-leave-to {
    opacity: 0 !important;
}

</style>