<template>
    <div :class="{ Badge: true, enabled: data.enabled, [world]: true }" :style="{ top, left }" @click="$emit('click', data.id)">
        <span class="inner">
            <img class="icon" :src="data.src" alt="">
            <p>{{ data.label }}</p>
            <img class="ring" :src="`/images/${ world }/hotspot-ring.png`" alt="" :style="{ top: `${ 50 + data['ring-offset'].y }%`, left: `${ 50 + data['ring-offset'].x }%` }" ref="ring-1">
            <img class="ring" :src="`/images/${ world }/hotspot-ring.png`" alt="" :style="{ top: `${ 50 + data['ring-offset'].y }%`, left: `${ 50 + data['ring-offset'].x }%` }" ref="ring-2">
        </span>
    </div>
</template>

<script>

// Modules.
import { mapStores } from 'pinia';
import gsap from 'gsap';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Badge',
    emits: ['click'],
    props: {
        data: { type: Object, required: true },
        world: { type: String, required: true },
    },
    computed: {
        ...mapStores(useStageStore),
        top() {

            return `${ this.data.coords[this.stageStore.platform].y * 100 }%`;
        
        },
        left() {

            return `${ this.data.coords[this.stageStore.platform].x * 100 }%`;
        
        },
    },
    mounted() {

        gsap.fromTo([this.$refs['ring-1'], this.$refs['ring-2']], { opacity: 1, scale: 1, x: '-50%', y: '-50%' }, { opacity: 0, scale: 1.6, x: '-50%', y: '-50%', duration: 1.5, ease: 'linear', repeat: -1, repeatDelay: 1, stagger: 0.5 });
    
    }
};

</script>

<style lang="scss" scoped>

.Badge {
    
    position: absolute;

    text-align: center;

    cursor: pointer;

}

.Badge .inner {
    display: block;
    transition: transform 300ms ease-in-out;
}

.Badge:hover .inner {
    transform: scale(1.1);
}

.Badge:not(.enabled) {
    opacity: 0.3;
    pointer-events: none;
}

img.icon {

    position: relative;
    z-index: 5;
    
    width: 150px;

    @include micro-height {
        width: 120px;
    }

    @include mobile {
        width: 100px;
    }

}

img.ring {
    position: absolute;
    z-index: 0;
}

.Badge.living-world img.ring {

    width: 105px;
    
    @include micro-height {
        width: 84px;
    }

    @include mobile {
        width: 70px;
    }

}

.Badge.afterlife img.ring {

    width: 99px;

    @include micro-height {
        width: 79px;
    }

    @include mobile {
        width: 66px;
    }

}

p {

    position: relative;
    z-index: 10;

    font-family: 'Caslon Antique', serif;
    font-size: 24px;
    color: color(White);
    text-transform: uppercase;
    white-space: pre-wrap;
    text-shadow: 0 5px 10px color(Black);

    margin-top: -58px;

    @include mobile {
        font-size: 18px;
        margin-top: -48px;
    }

}

</style>