<template>
    <div class="Intro" ref="node">
        <Lockup
            :title="$t(`${ this.world }.hotspots.intro.lockup.title`)"
            :src="{ top: $t(`${ this.world }.hotspots.intro.lockup.src.top`), bottom: $t(`${ this.world }.hotspots.intro.lockup.src.bottom`) }"
            :fontSize="{ desktop: $t(`${ this.world }.hotspots.intro.lockup.fontSize.desktop`), mobile: $t(`${ this.world }.hotspots.intro.lockup.fontSize.mobile`) }"
            :theme="$t(`${ this.world }.hotspots.intro.lockup.theme`)"
        />
        <img class="characters" :src="$t(`${ this.world }.hotspots.intro.characters.src`)" alt="" ref="characters">
    </div>
</template>

<script>

// Modules.
import { mapStores } from 'pinia';
import gsap from 'gsap';

// Components.
import Lockup from '@/components/Lockup';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Intro',
    components: {
        Lockup,
    },
    props: {
        world: { type: String, required: true },
    },
    computed: {
        ...mapStores(useStageStore),
    },
    mounted() {
        // gsap.fromTo(this.$refs.characters, { x: '-50%', y: '100%' }, { x: '-50%', y: '0%', ease: 'back.out(1.7)', duration: 0.75, delay: 0.5 });
    },
    methods: {
        animateOut(cb) {

            gsap.to(this.$refs.node, { opacity: 0, duration: 1, ease: 'sine.out', onComplete: cb });

        }
    }
};

</script>

<style lang="scss" scoped>

.Intro {

    position: fixed;
    inset: 0;
    z-index: 20;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: color(Black, 0.4);

    text-align: center;

}

.Intro .Lockup {
    margin-bottom: 15vh;
}

.Intro img.characters {

    position: absolute;
    bottom: 60px;
    left: 50%;

    transform: translateX(-50%);

    max-width: 50vw;
    max-height: 28vh;

    @include mobile {
        max-width: 100vw;
    }

}

</style>