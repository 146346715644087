<template>
    <div class="Trailer modal-parent">
        <h3 
            class="gradient-text modal-header" 
            :style="{ fontSize: `${ $t(`living-world.hotspots.scene.watch-trailer.header.fontSize.${ stageStore.platform }`) }px`}"
        >
            {{ $t('living-world.hotspots.scene.watch-trailer.header.label') }}
        </h3>
        <h5 class="modal-subheader">{{ $t('living-world.hotspots.scene.watch-trailer.subheader') }}</h5>
        <div class="videos">
            <div class="card" v-for="trailer in $tm('living-world.hotspots.scene.watch-trailer.trailers')" :key="trailer.title">
                <iframe :src="`https://www.youtube.com/embed/${ trailer.id }`" :title="trailer.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>

import { mapStores } from 'pinia';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Trailer',
    computed: {
        ...mapStores(useStageStore),
    },
    mounted () {

        this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.BEETLEJUICE_2);

    }
};

</script>

<style lang="scss" scoped>

.Trailer {
    text-align: center;
    color: color(White);
}

h3 {
    background: linear-gradient(135deg, color(White) 20%, color(LivingWorld));
}

h5 {
    margin-top: 10px;
    @include mobile {
        font-size: 16px;
    }
}

.videos {
    margin: 60px auto 0;
}

.card:nth-child(n + 2) {
    
    margin-top: 80px;

    @include phone {
        margin-top: 40px;
    }

}

.card:nth-child(odd) {
    
    margin-right: 15vw;

    @include mobile {
        margin-right: 0;
    }

}

.card:nth-child(even) {

    margin-left: 15vw;

    @include mobile {
        margin-left: 0;
    }

}

.card iframe {

    width: 800px;
    aspect-ratio: 16/9;

    @media (max-width: 1400px) {
        width: 600px;
    }

    @include tablet {
        width: 100%;
        max-width: 600px;
    }

}

</style>