<template>
    <div>
        <TopBar />
        <RouterView 
            :key="$route.fullPath" 
        />
        <BottomBar />
        <Lightning />
    </div>
</template>

<script>

// Modules.
import debounce from 'lodash.debounce';
import preloader from 'preloader';
import { mapStores } from 'pinia';

// Components.
import TopBar from '@/components/TopBar';
import BottomBar from '@/components/BottomBar';
import Lightning from '@/components/Lightning';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'App',
    components: {
        TopBar,
        BottomBar,
        Lightning,
    },
    computed: {
        ...mapStores(useStageStore),
    },
    data() {

        return {
            loader: null,
        };
    
    },
    created() {

        this.loader = preloader({ xhrImages: false });
    
        this.eventBus.on(this.constants.LOAD_MANIFEST, manifest => {

            this.loader.reset();

            manifest.forEach(asset => {

                this.loader.add(asset);
            
            });

            this.loader.load();
        
        });
    
    },
    mounted() {

        window.addEventListener('resize', debounce(this.setStage, 250));
        this.setStage();
    
    },
    methods: {
        setStage() {

            const { innerWidth, innerHeight } = window;
            this.stageStore.setDimensions(innerWidth, innerHeight);
        
        }
    }
};

</script>

<style scoped>

div {

    display: flex;
    flex-direction: column;
    align-items: center;

}

</style>

<style lang="scss">

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1000ms ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

</style>