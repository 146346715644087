<template>
    <div class="Cast modal-parent">
        <h3 
            class="gradient-text modal-header"
            :style="{ fontSize: `${ $t(`living-world.hotspots.scene.meet-the-cast.header.fontSize.${ stageStore.platform }`) }px`}"
        >
            {{ $t('living-world.hotspots.scene.meet-the-cast.header.label') }}
        </h3>
        <div class="characters">
            <div class="card" v-for="character in $tm('living-world.hotspots.scene.meet-the-cast.characters')" :key="character.name">
                <div class="character">
                    <img :src="character.src" :alt="character.name">
                </div>
                <h4>{{ character.name }}</h4>
            </div>
        </div>
    </div>
</template>

<script>

import { mapStores } from 'pinia';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Cast',
    computed: {
        ...mapStores(useStageStore),
    },
    mounted () {

        this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.CUTOFF_SCREAM);

    }
};

</script>

<style lang="scss" scoped>

.Cast {
    text-align: center;
    color: color(White);
}

h3 {
    background: linear-gradient(135deg, color(White) 20%, color(LivingWorld));
}

.characters {

    width: 660px;
    margin: 40px auto 0;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 60px;

    @include mobile {
        width: 100%;
        column-gap: 30px;
    }

    @include phone {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 50px;
    }

}

.card:nth-child(even) {
    
    margin-top: 50px;

    @include phone {
        margin-top: 0;
    }

}

.card .character {
    
    max-width: 300px;
    margin: 0 auto;
    padding: 1px;

    background: linear-gradient(to bottom, color(LivingWorld), color(Black), color(LivingWorld));

}

.card img {
    display: block;
    width: 100%;
}

.card h4 {
    
    font-size: 24px;
    text-transform: uppercase;
    color: color(LivingWorld);

    margin-top: 16px;

}

</style>