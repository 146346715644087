// Modules.
import { defineStore } from 'pinia';

export const useHeaderStore = defineStore('header', {
    state: () => ({
        isBackVisible: false,
        isVolumeVisible: true,
        isSoundOn: false,
    }),
    getters: {
        visibility: state => ({ back: state.isBackVisible, volume: state.isVolumeVisible }),
    },
    actions: {
        setBackVisibility(isVisible) {

            this.isBackVisible = isVisible;
        
        },
        setVolumeVisibility(isVisible) {

            this.isVolumeVisible = isVisible;
        
        },
        setSound(isOn) {

            this.isSoundOn = isOn;
        
        },
    }
});