<template>
    <div :class="{ Lockup: true, [theme]: true, [font]: true }">
        <img class="top" :src="src.top" alt="">
        <div class="text-wrapper">
            <h2 class="gradient-text" :style="{ fontSize: `${ fontSize[stageStore.platform] }px` }">{{ title }}</h2>
            <h2 class="shadow-text" :style="{ fontSize: `${ fontSize[stageStore.platform] }px` }">{{ title }}</h2>
        </div>
        <img class="bottom" :src="src.bottom" alt="">
    </div>
</template>

<script>

// Modules.
import { mapStores } from 'pinia';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Lockup',
    props: {
        title: { type: String, required: true },
        src: { type: Object, default: () => ({ top: '', bottom: '' }) },
        fontSize: { type: Object, default: () => ({ desktop: '', mobile: '' }) },
        theme: { type: String, validator(value) {

            return ['green', 'purple', 'white'].includes(value); 

        }, default: 'white' },
        font: { type: String, validator(value) {

            return ['ramaraja', 'caslon'].includes(value); 

        }, default: 'ramaraja' },
    },
    computed: {
        ...mapStores(useStageStore),
    }
};

</script>

<style lang="scss" scoped>

.Lockup {

    display: inline-flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

}

img.top {
    
    width: 145px;

    @include mobile {
        width: 100px;
    }

}

img.bottom {
    
    width: 210px;

    @include mobile {
        width: 140px;
    }

}

.Lockup.green h2.gradient-text {
    background: linear-gradient(135deg, color(White) 20%, color(LivingWorld));
}

.Lockup.purple h2.gradient-text {
    background: linear-gradient(135deg, color(White) 20%, color(Afterlife));
}

.Lockup.white h2.gradient-text {
    background: color(White);
}

.Lockup h2 {
    text-transform: uppercase;
    white-space: pre-wrap;
    line-height: 0.9;
}

.Lockup h2.shadow-text {
    
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    text-shadow: 0 12px 12px color(Black);
    color: transparent;

}

.Lockup.ramaraja {
    font-family: 'Ramaraja', serif;
}

.Lockup.caslon {
    font-family: 'Caslon Antique', serif;
}

.Lockup.ramaraja h2 {

    padding: 22px 0 4px;
    margin-bottom: -2px;
   
    @include mobile {
        padding: 16px 0 4px;
    }

}

.Lockup.caslon h2 {
    
    line-height: 1.2;
    padding: 16px 0 8px;

    @include mobile {
        padding: 10px 0 4px;
    }

}

.text-wrapper {
    position: relative;
}

</style>