<template>
    <div class="Wallpaper modal-parent">
        <h3 
            class="gradient-text modal-header" 
            :style="{ fontSize: `${ $t(`afterlife.hotspots.scene.download-wallpaper.header.fontSize.${ stageStore.platform }`) }px`}"
        >
            {{ $t('afterlife.hotspots.scene.download-wallpaper.header.label') }}
        </h3>
        <h5 class="modal-subheader">{{ $t('afterlife.hotspots.scene.download-wallpaper.subheader') }}</h5>
        <div class="files">
            <div class="card" v-for="file in $tm('afterlife.hotspots.scene.download-wallpaper.files')" :key="file.name">
                <div class="preview">
                    <img :class="{ [file.orientation]: true }" :src="file.preview" :alt="file.name">
                </div>
                <div class="sizes">
                    <a v-for="size in file.sizes" :key="size.label" :href="size.src" download @click="sendEvent('download_wallpaper')">{{ size.label }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapStores } from 'pinia';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Wallpaper',
    computed: {
        ...mapStores(useStageStore),
    },
    mounted () {

        this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.SCREAM_2);

    },
    methods: {
        sendEvent(value) {

            gtag('event', 'button_click', {
                'button_click_value': value,
            });
        
        }
    }
};

</script>

<style lang="scss" scoped>

.Wallpaper {
    text-align: center;
    color: color(White);
}

h3 {
    background: linear-gradient(135deg, color(White) 20%, color(Afterlife));
}

h5 {
    margin-top: 10px;
}

.files {
    
    margin-top: 60px;

    @include phone {
        margin-top: 40px;
    }

}

.card:nth-child(n + 2) {
    
    margin-top: 90px;

    @include phone {
        margin-top: 75px;
    }

}

.card .preview {

    display: inline-block;

    padding: 1px;

    background: linear-gradient(to bottom, color(Afterlife), color(Black), color(Afterlife));

}

.card .preview img {
    display: block;
    width: 100%;
}

.card .preview img.landscape {
    max-width: 660px;
}

.card .preview img.portrait {
    max-width: 440px;
}

.sizes {
    
    display: flex;
    justify-content: center;
    gap: 20px;

    margin-top: 10px;

}

.sizes a {

    font-family: 'Caslon Antique', serif;
    font-size: 20px;
    color: color(Afterlife);
    text-decoration: none;

    transition: color 300ms ease, border-color 300ms ease;

    border-bottom: 1px solid color(Afterlife);

    @include phone {
        font-size: 18px;
    }

}

.sizes a:hover {
    color: color(White);
    border-color: color(White);
}

</style>