<template>
    <div :class="{ TopBar: true, shrinkLogo: headerStore.visibility.back }">
        <div class="back">
            <img v-show="headerStore.visibility.back" src="/images/back.png" alt="" @click="back">
        </div>
        <RouterLink to="/" class="logo">
            <img :src="$t('logo')" alt="">
        </RouterLink>
        <div @click="toggleVolume" :class="{ volume: true, muted: !volume }">
            <img v-show="headerStore.visibility.volume" src="/images/volume.png" alt="">
        </div>
    </div>
</template>

<script>

// Modules.
import { mapStores } from 'pinia';
import { Howl } from 'howler';

// Store.
import { useHeaderStore } from '@/store/header';

export default {
    name: 'TopBar',
    computed: {
        ...mapStores(useHeaderStore),
    },
    data() {

        return {
            volume: 1,
            ambientAudio: null,
        };
    
    },
    mounted() {

        this.eventBus.on(this.constants.PLAY_AUDIO, track => {

            this.playAudio(this.$t(`sounds.${ track }`), track === this.constants.SOUNDS.AMBIENT, track);
        
        });

    },
    methods: {
        back() {

            this.eventBus.emit(this.constants.BACK); 
        
        },
        toggleVolume() {

            this.volume = Math.abs(this.volume - 1);

            if (this.ambientAudio) {

                this.ambientAudio.volume(this.volume);
            
            }

        },
        playAudio(src, loop, track) {

            this.fadeAmbientAudio(1, 0);

            const sound = new Howl({
                src,
                volume: this.volume,
                loop,
            });

            sound.play();
            sound.on('end', () => {

                this.fadeAmbientAudio(0, 1);
                this.eventBus.emit(this.constants.AUDIO_ENDED, track);
            
            });

            if (loop) {

                this.ambientAudio = sound;
            
            }

        },
        fadeAmbientAudio(from, to) {

            if (this.volume === 0 || !this.ambientAudio) {

                return; 

            }

            this.ambientAudio.fade(from, to, 100);
        
        },
    }
};

</script>

<style lang="scss" scoped>

.TopBar {

    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    width: 100%;
    padding: 30px 10vw;

    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: start;

    @media (max-width: 1920px) {
        padding: 30px 5vw;
    }

    @include mobile {
        padding: 15px;
    }

}

.back img,
.volume img {
    
    cursor: pointer;
    pointer-events: auto;

    transition: transform 300ms ease;

}

.back img:hover,
.volume img:hover {
    transform: scale(1.1);
}

.back img:active,
.volume img:active {
    transform: scale(1);
}

.back {
    justify-self: start;
}

.back img {
    
    height: 38px;

    @include phone {
        height: 34px;
    }

}

.logo {
    justify-self: center;
}

.logo img {

    height: 140px;

    transform-origin: center top;
    transition: transform 300ms ease;

    cursor: pointer;

    @include mobile {
        height: 110px;
    }

    @include phone {
        height: 80px;
    }

}

.shrinkLogo .logo img {
    transform: scale(0.75);
}

.volume {
    justify-self: end;
    transition: opacity 300ms ease;
}

.volume.muted {
    opacity: 0.25;
}

.volume img {

    height: 32px;

    @include phone {
        height: 28px;
    }

}

</style>