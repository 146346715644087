<template>
    <div class="Teaser">
        <video v-if="stageStore.platform === 'desktop'" :src="$t(`${ this.world }.teaser.src.desktop`)" autoplay muted playsinline @ended="$emit('onEnd')"></video>
        <video v-else :src="$t(`${ this.world }.teaser.src.mobile`)" autoplay muted playsinline @ended="$emit('onEnd')"></video>
    </div>
</template>

<script>

// Modules.
import { mapStores } from 'pinia';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Teaser',
    emits: ['onEnd'],
    props: {
        world: { type: String, required: true },
    },
    computed: {
        ...mapStores(useStageStore),
    },
};

</script>

<style lang="scss" scoped>

.Teaser,
video {
    width: 100%;
    height: 100%;
}

video {
    object-fit: cover;
}

</style>