<template>
    <div class="Spooky modal-parent">
        <h3 
            class="gradient-text modal-header"
            :style="{ fontSize: `${ $t(`living-world.hotspots.scene.in-my-spooky-era.header.fontSize.${ stageStore.platform }`) }px`}"
        >
            {{ $t('living-world.hotspots.scene.in-my-spooky-era.header.label') }}
        </h3>
        <br>
        <div class="videos">
            <div class="card" v-for="trailer in $tm('living-world.hotspots.scene.in-my-spooky-era.trailers')" :key="trailer.title">
                <iframe :src="`https://www.youtube.com/embed/${ trailer.id }`" :title="trailer.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>

import { mapStores } from 'pinia';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Spooky',
    computed: {
        ...mapStores(useStageStore),
    },
    mounted () {

        this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.SCREAM);

    }
};

</script>

<style lang="scss" scoped>

.Spooky {
    text-align: center;
    color: color(White);
}

h3 {
    background: linear-gradient(135deg, color(White) 20%, color(LivingWorld));
}

.videos {

    margin: 60px auto 0;

    display: inline-flex;
    gap: 60px;

    @include desktop {

        display: inline-grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 60px;

    }

    @include mobile {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

}

.card:nth-child(2) {
    
    display: flex;
    align-items: center;
    grid-row: span 2;

    @include mobile {
        display: block;
        grid-row: span 1;
    }

}

.card iframe {

    width: 300px;
    aspect-ratio: 9/16;

    @include mobile {
        width: 100%;
        max-width: 300px;
    }

}

</style>