<template>
    <div :class="{ Scene: true, [world]: true }" ref="scene">
        <img v-if="stageStore.platform === 'desktop'" class="bg" ref="bgImg" :src="$t(`${ this.world }.hotspots.scene.background.desktop`)" alt="">
        <img v-else class="bg" ref="bgImg" :src="$t(`${ this.world }.hotspots.scene.background.mobile`)" alt="">
        <Transition name="fade" @enter="onEnter">
            <span v-if="showBadges">
                <Badge v-for="badge in $tm(`${ this.world }.hotspots.scene.badges`)" :key="badge.id" :data="badge" @click="openModal" ref="badge" :world="world" />
            </span>
        </Transition>
    </div>
</template>

<script>

// Modules.
import Panzoom from '@panzoom/panzoom';
import { mapStores } from 'pinia';
import gsap from 'gsap';

// Components.
import Badge from '@/components/World/Badge';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Scene',
    emits: ['openModal'],
    components: {
        Badge,
    },
    props: {
        world: { type: String, required: true },
        showBadges: { type: Boolean, default: false },
    },
    computed: {
        ...mapStores(useStageStore),
    },
    mounted() {

        const { width, height } = this.stageStore.dimensions;
        const { sceneWidth, sceneHeight } = this.resizeCanvas();
        let startX = 0;
        let startY = 0;

        if (sceneWidth > width) {

            startX = ((sceneWidth - width) / 2) * -1;
        
        } else {

            startY = ((sceneHeight - height) / 2) * -1;
        
        }

        const panzoom = Panzoom(this.$refs.scene, {
            maxScale: 1,
            disableZoom: true,
            contain: 'outside',
            startX,
            startY,
        });

        this.stageStore.$subscribe(() => {

            this.resizeCanvas();
            panzoom.reset();
        
        });

    },
    methods: {
        resizeCanvas() {

            const { width, height } = this.stageStore.dimensions;
            const aspectRatio = (this.stageStore.platform === 'desktop') ? 16/9 : 4/3; // TODO: Update to final specs.
            let sceneWidth, sceneHeight;

            if ((width / height) < aspectRatio) {

                sceneWidth = (height * aspectRatio);
                sceneHeight = height;

            } else {

                sceneWidth = width;
                sceneHeight = (width * (1 / aspectRatio));

            }

            this.$refs.scene.style.width = `${ sceneWidth }px`;
            this.$refs.scene.style.height = `${ sceneHeight }px`;

            return { sceneWidth, sceneHeight };

        },
        openModal(id) {

            gtag('event', 'button_click', {
                'button_click_value': `hotspot_${ id }`,
            });

            this.$emit('openModal', id);
        
        },
        onEnter() {

            gsap.fromTo(this.$refs.badge.map(badge => badge.$el), { opacity: 0, x: '-50%', y: '-50%', scale: 0.75 }, { opacity: 1, x: '-50%', y: '-50%', scale: 1, duration: 0.75, stagger: 0.15, ease: 'power3.out' });
        
        }
    }
};

</script>

<style lang="scss" scoped>

.Scene {
    position: relative;
}

.bg {
    width: 100%;
    height: 100%;
}

.Scene.living-world .bg {
    opacity: 0.5;
}

.Scene.afterlife .bg {
    opacity: 0.8;
}

// Vue Transition:

.fade-enter-active {
    transition: opacity 0.5s ease;
}

.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from {
  opacity: 0;
}

</style>