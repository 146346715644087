<template>
    <div class="Generator modal-parent">
        <h3 
            class="gradient-text modal-header"
            :style="{ fontSize: `${ $t(`afterlife.hotspots.scene.afterlife-generator.header.fontSize.${ stageStore.platform }`) }px`}"
        >
            {{ $t('afterlife.hotspots.scene.afterlife-generator.header.label') }}
        </h3>
        <div>
            <iframe :src="`https://www.youtube.com/embed/${ $t('afterlife.hotspots.scene.afterlife-generator.youtubeId') }`" :title="$t('afterlife.hotspots.scene.afterlife-generator.header')" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <a class="rsvp" :href="$t('afterlife.hotspots.scene.afterlife-generator.rsvp.url')" target="_blank" @click="sendEvent('afterlife_generator')">
            <img src="/images/afterlife-btn.png" alt="">
            <span :style="{ fontSize: `${ $t(`afterlife.hotspots.scene.afterlife-generator.fontSize.${ stageStore.platform }`) }px` }">{{ $t('afterlife.hotspots.scene.afterlife-generator.rsvp.label') }}</span>
        </a>
    </div>
</template>

<script>

// Modules.
import { mapStores } from 'pinia';

// Store.
import { useStageStore } from '@/store/stage';

export default {
    name: 'Generator',
    computed: {
        ...mapStores(useStageStore),
    },
    mounted () {

        this.eventBus.emit(this.constants.PLAY_AUDIO, this.constants.SOUNDS.JUICE_LOOSE);

    },
    methods: {
        sendEvent(value) {

            gtag('event', 'link_click', {
                'link_click_value': value,
            });
        
        }
    }
};

</script>

<style lang="scss" scoped>

.Generator {
    text-align: center;
    color: color(White);
}

h3 {
    background: linear-gradient(135deg, color(White) 20%, color(Afterlife));
}

iframe {
    
    width: 100%;
    max-width: 300px;
    aspect-ratio: 9/16;
    margin-top: 60px;

    @include phone {
        margin-top: 40px;
    }

}

a.rsvp {

    position: relative;
    display: inline-block;

    font-family: 'Ramaraja', serif;
    color: color(White);
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;

    margin-top: 60px;

    @include phone {
        margin-top: 40px;
    }

}

a.rsvp img {
    
    width: 230px;
    aspect-ratio: 466/152; // Intrinsic size.

    @include mobile {
        width: 200px;
    }

}

a.rsvp span {

    position: absolute;
    top: 50%;
    left: 0;

    transform: translateY(-50%);

    width: 100%;

}

</style>